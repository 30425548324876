$black: #000;
$white: #ededed;
$accent: #f00;
$valid: #00ffc3;
$invalid: $accent;

:export {
  black: $black;
  white: $white;
  accent: $accent;
}
