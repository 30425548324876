@mixin accessibility-outline {
  outline: 3px solid $accent;
  outline-offset: 3px;
}

@mixin merge-transitions(
  $properties,
  $time: $default-time,
  $timing-function: ease-in-out
) {
  $transition-list: ();
  $property-list: $properties;
  @each $property in $property-list {
    $transition-list: append(
      $transition-list,
      $property $time $timing-function,
      comma
    );
  }
  transition: $transition-list;
}

@mixin border(
  $left: true,
  $right: true,
  $top: true,
  $bottom: true,
  $weight: 1px,
  $color: var(--foreground),
  $style: solid
) {
  @if $top {
    border-top: $weight $style $color;
  }
  @if $right {
    border-right: $weight $style $color;
  }
  @if $bottom {
    border-bottom: $weight $style $color;
  }
  @if $left {
    border-left: $weight $style $color;
  }
}

@mixin rounded-corners(
  $top-left: true,
  $top-right: true,
  $bottom-right: true,
  $bottom-left: true,
  $radius: $border-radius
) {
  @if $top-left {
    border-top-left-radius: $radius;
  }
  @if $top-right {
    border-top-right-radius: $radius;
  }
  @if $bottom-right {
    border-bottom-right-radius: $radius;
  }
  @if $bottom-left {
    border-bottom-left-radius: $radius;
  }
}

@mixin degular($wght: 400, $opsz: 12, $ital: false) {
  $weight: if($wght < 200, 200, if($wght > 800, 800, $wght));
  $optical: if($opsz < 6, 6, if($opsz > 72, 72, $opsz));
  $italic: if($ital==true, 1, 0);
  font-variation-settings: 'wght' $weight, 'opsz' $optical, 'ital' $italic;
}
